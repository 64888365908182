import type { NextImageProps } from "@/next-js-app/components/common/NextImage/NextImage.types";
import { ImageWithFetchPrio } from "react-app/src/components/Common/Image/ImageWithFetchPrio";
/**
@deprecated
Use ImageWithFetchPrio instead if in the `React app`.
Use NextImage instead if in the `Next.js app`
XD-15933
*/
export const NextImage = ({ alt, priority, src, ...props }: NextImageProps) => {
  const fetchPriority =
    priority === undefined ? "auto" : priority ? "high" : "low";
  return (
    <ImageWithFetchPrio
      alt={alt}
      fetchPriority={fetchPriority}
      src={src.toString()}
      {...props}
    />
  );
};
